import React from 'react';

import FullPageBanner from '../components/Banner/FullPageBanner';
import Testimonials from '../components/Testimonials/Testimonials';
import BoxGridLockup from '../components/BoxGridLockup/BoxGridLockup';
import urls from '../data/urls';
import aneeshAuthorImage from '../img/testimonials/aneesh-headshot.png';
import willWhiteImage from '../img/testimonials/will-white-headshot.jpeg';
import chrisHallerImage from '../img/testimonials/chris-haller-headshot.jpeg';

const IndexPage = () => (
    <div className="body_wrapper">
      <FullPageBanner />
      <BoxGridLockup
        title="What Are You Looking To Do?"
      >
        <BoxGridLockup.Item
          icon="ti-star"
          title="Build a prototype"
          details="We love R&D! Create your proof-of-concept or test experiential iterations with us. Our tiger team will give you the next product concept before corporate finishes their next meeting."
          link={urls.rnd}
        />
        <BoxGridLockup.Item
          icon="ti-pencil-alt"
          title="Create an application"
          details="Build sleek, interactive apps that perform across all devices. We’ll integrate your existing technology, craft the look & feel, and leave quality documentation so you can easily adjust it as you continue to scale."
          link={urls.software}
        />
        <BoxGridLockup.Item
          icon="ti-paint-roller"
          title="Improve existing technology"
          details="We’ll review your ecosystem as a whole and develop from within your framework. Whether you’re Onsite or in The Cloud, we’ll get your system firing on all cylinders."
          link={urls.software}
        />
        <BoxGridLockup.Item
          icon="ti-vector"
          title="Hardware Integration and IoT"
          details="Connect your hardware to the cloud and track real-time analytics at a granular level with our interface expertise. We’ll get your devices talking in no time."
          link={urls.software}
        />
        <BoxGridLockup.Item
          icon="ti-control-shuffle"
          title="Build or migrate infrastructure"
          details="Got data, need to process? Whether you have 1 TB or 10 TB, we’ll speed your processing time. We’re experts at improving On-Prem systems, migrating to The Cloud, and establishing new systems from scratch."
          link={urls.devOps}
        />
        <BoxGridLockup.Item
          icon="ti-face-smile"
          title="Augment headcount"
          details="Without the overhead of a full time employee, we can keep your project moving forward with efficiency."
          link={urls.staffAugmentation}
        />
      </BoxGridLockup>
      <Testimonials>
        <Testimonials.Testimonial
          image={willWhiteImage}
          text="Thomas is hands down the most impactful partner we've brought in. Start to finish, he delivered above expectations; and we look forward to partnering with him again."
          authorName="Will W."
          authorTitle="Eng Manager, LinkedIn"
        />
        <Testimonials.Testimonial
          image={chrisHallerImage}
          text="I would definitely work with the team at Opna again! We needed outside knowledge in order to activate key accessibility features. Opna quickly took up the task and was easy to work with."
          authorName="Chris Haller"
          authorTitle="Founder &amp; CEO, Konveio"
        />
        <Testimonials.Testimonial
          image={aneeshAuthorImage}
          text="Their team is very fast and incredibly bright. If I want to get a difficult technical project off the ground efficiently, I always call on these guys."
          authorName="Aneesh S."
          authorTitle="Founder, 23 Bytes"
        />
      </Testimonials>
    </div>
);
export default IndexPage;
