import React from 'react';
import Reveal from 'react-reveal';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import urls from '../../data/urls';

const CrmBanner = () => {
  const bannerData = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "home-banner"}) {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
    }
  `);
  const banner = bannerData.contentfulAsset.fluid;

  return (
    <section className="new_startup_banner_area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <Reveal left cascade ssrReveal>
              <div className="new_startup_img">
                <div className="line line_one"><img src={require('../../img/new/line_01.png')} alt="" /></div>
                <div className="line line_two"><img src={require('../../img/new/line_02.png')} alt="" /></div>
                <div className="line line_three"><img src={require('../../img/new/line_03.png')} alt="" /></div>
                <Img fluid={banner} />
              </div>
            </Reveal>
          </div>
          <div className="col-lg-6 d-flex align-items-center">
            <div className="new_startup_content">
              <h2 className="f_700 f_size_40 l_height50 w_color mb_20">
                Ready to see
                <br />
                <span>OPNA</span>
                {' '}
                in Action?
              </h2>
              <p className="f_400 w_color l_height28">OPNA is a collective of elite software engineers coming from the Bay Area’s leading innovators. We work closely with clients to bring products to market, develop business critical applications and fix urgent technology issues that their current infrastructure can’t address. We take pride in our work and always deliver as much impact as possible.</p>
              <p className="f_400 w_color l_height28">Whatever your goal is, we’ll work with you to make it happen!</p>
              <div className="action_btn d-flex align-items-center mt_40">
                <Link to={urls.contactUs} className="btn_hover app_btn">
                  Contact us
                  <i className="ti-arrow-right" />
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}
export default CrmBanner;
