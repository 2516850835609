import React, { Component } from 'react';
import Slider from 'react-slick';
import Fade from 'react-reveal/Fade';
import Noop from '../../util/Noop';

import './Testimonials.scss';


const Testimonial = Noop;

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  // slickNext and slickPrev don't reset the timer causing
  // slides to be autoplayed almost immediately after a navigation.
  // use this to reset the timer and prevent that bad UX
  resetSlickTimer() {
    this.slider.slickPause();
    this.slider.slickPlay();
  }

  next() {
    this.slider.slickNext();
    this.resetSlickTimer();
  }

  previous() {
    this.slider.slickPrev();
    this.resetSlickTimer();
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 8000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <section className="testimonials sec_pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 d-flex align-items-center">
              <div className="testimonials__left-container">
                <Fade bottom cascade>
                  <div className="seo_sec_title wow fadeInUp" data-wow-delay="0.3s">
                    <h2>What people say about us</h2>
                  </div>
                </Fade>
                <div className="testimonials__navigation">
                  <i 
                    className="ti-angle-left testimonials__navigate-previous" 
                    onClick={this.previous} 
                  />
                  <i 
                    className="ti-angle-right testimonials__navigate-next" 
                    onClick={this.next} 
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <div className="testimonials__right-container d-flex align-items-center">
                <Slider ref={(c) => (this.slider = c)} className="testimonials__slider" {...settings}>
                  {
                    React.Children.map(this.props.children, (child, index) => {
                      const {
                        image,
                        text,
                        authorName,
                        authorTitle,
                      } = child.props;

                      return (
                        <div className="testimonials__slider-item" key={index}>
                          <div className="testimonials__author-image">
                            {image ? <img src={image} alt="" /> : null}
                          </div>
                          <p>{text}</p>
                          <h5>{authorName}</h5>
                          <h6>{authorTitle}</h6>
                        </div>
                      );
                    })
                  }
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonials.Testimonial = Testimonial;

export default Testimonials;
