import React, { Component } from 'react';
import { Link } from 'gatsby';
import Sectitle from '../Title/Sectitle';
import Noop from '../../util/Noop';
import './BoxGridLockup.scss';

const BoxGridLockupItem = Noop;

class BoxGridLockup extends Component {
  render() {
    const {
      title,
      details,
      children,
    } = this.props;

    return (
      <section className="sec_pad" id="work">
        <div className="container">
          <Sectitle sClass="sec_title text-center mb_70" Title={title} tClass="t_color3" TitleP={details} />
          <div className="row app_service_info">
            {
              React.Children.map(children, (child, index) => {
                const {
                  icon,
                  title,
                  details,
                  link,
                } = child.props;

                return (
                  <div className="col-lg-4 box-grid-lockup__item" key={index}>
                    <div className="app_service_item wow fadeInUp" data-wow-delay="0.2s">
                      <i className={`app_icon ${icon}`} />
                      <h5 className="f_p f_size_18 f_600 t_color3 mt_40 mb-30">{title}</h5>
                      <p className="f_400 f_size_15 mb-30">{details}</p>
                      <Link to={link} className="learn_btn_two">
                        Learn more
                        <i className="ti-arrow-right" />
                      </Link>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </section>
    );
  }
}

BoxGridLockup.Item = BoxGridLockupItem;

export default BoxGridLockup;
